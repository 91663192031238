import React, { useEffect, useState, useRef } from "react";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { listSharedApps, searchSharedApps } from "../../graphql/queries";
import { deleteSharedApp } from "../../graphql/mutations";
import HOME_IMPROVEMENT from "../../images/HI_1.png";
import ROOF from "../../images/ROOF_1.png";
import SOLAR from "../../images/SOLAR_1.png";
import BATTERY from "../../images/BATTERY_1.png";
import CORP from "../../images/CORP_1.png";
import PLUS from "../../images/PLUS_1.png";
import BLANK from "../../images/BLANK_1.png";
import { BiChevronRight, BiChevronLeft, BiChevronsLeft} from "react-icons/bi";
import {
  Heading,
  Button as LumosButton,
  AlertInline,
  Modal,
  ModalControls,
  useNotify,
  Icon,
  Badge
 } from "@loanpal/lumos__core";
import { Box } from "theme-ui";
import Loading from "../../utils/loading";
import NavBar from "../../shared/nav-bar";
import { formFields, components } from "../../utils/authentication";
import { useNavigate } from "react-router-dom";
import { Status, StatusVariant  } from "../../utils/status";
import {
  formatDateTime
} from "../../utils/format";

import {
  Authenticator,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  SearchField,
  View,
  Flex,
  Menu,
  MenuItem,
  SelectField
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "../../aws-exports";
Amplify.configure(awsExports);

const Partners = () => {
  const [SharedApps, setSharedApps] = useState([]);
  const [sharedAppsCount, setSharedAppsCount] = useState();
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousToken] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [isOpen, toggleOpen] = useState(false);
  const [appId, setAppId] = useState();
  const [appClientId, setAppClientId] = useState();
  const { addSnack } = useNotify();
  const inputRef = useRef(null);
  const [pageSize, setPageSize] = useState(35);

  const hasNext = !!nextNextToken && previousTokens.length < totalPages - 1
  const hasPrev = previousTokens.length

  let navigate = useNavigate();

  useEffect(() => {
    console.log('%c i fire once', 'background: #222; color: #bada55', nextToken);
    fetchSharedApps();
    // eslint-disable-next-line
  }, [nextToken, pageSize]);

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e, 10));
  };

   async function fetchSharedApps() {
    try {
      setSpinner(true);
      const appData = await API.graphql(graphqlOperation(searchSharedApps, {
        sort: {
          field: 'updatedAt',
          direction: 'desc'
        },
        limit: pageSize,
        nextToken
      }));
      console.log("APPS", appData.data, appData.data.searchSharedApps.items.length);
      if (appData.data.searchSharedApps.items.length > 0) {
        const apps = appData.data.searchSharedApps.items;
        const pages = Math.ceil(appData.data.searchSharedApps.total/pageSize);
        if (apps && apps.length > 0) {
          setSharedApps([...apps]);
          setSharedAppsCount(appData.data.searchSharedApps.total);
          setNextNextToken(appData.data.searchSharedApps.nextToken);
          setTotalPages(pages);
        }
      }
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      console.log("error fetching Shared Apps", err);
    }
  }

async function fetchSharedAppByStatus(st) {
  try {
    console.log(inputRef.current.value);
    inputRef.current.focus();
    inputRef.current.value = "";
    if (st === "ALL")  { fetchSharedApps(); return; }
    setSpinner(true);
    let nextToken = null;
    let SharedApps = [];

    // Keep fetching until there's no more nextToken
    do {
      const appData = await API.graphql(
        graphqlOperation(listSharedApps, {
          filter: { status: { eq: st } },
          nextToken: nextToken
        })
      );
      SharedApps = SharedApps.concat(appData.data.listSharedApps.items);
      nextToken = appData.data.listSharedApps.nextToken; // Update nextToken
    } while (nextToken);

    setSharedApps(SharedApps);
    setSharedAppsCount(SharedApps.length);
    handleResetPage();
    setSpinner(false);
  } catch (err) {
    console.log("error fetching Shared Apps", err);
  }
}

//  async function fetchSharedAppByStatus(st) {
//   try {
//         console.log(inputRef.current.value);
//         inputRef.current.focus();
//         inputRef.current.value = "";
//         if (st === "ALL")  { fetchSharedApps(); return; }
//         setSpinner(true);
//         const appData = await API.graphql(graphqlOperation(listSharedApps, { filter: { status: { eq: st } }}));
//         const SharedApps = appData.data.listSharedApps.items;
//         setSharedApps(SharedApps);
//         setSharedAppsCount(appData.data.listSharedApps.items.length);
//         setNextNextToken(appData.data.listSharedApps.nextToken);
//         handleResetPage();
//         setSpinner(false);
//       } catch (err) {
//         console.log("error fetching Shared Apps", err);
//     }
//   }

  async function fetchSharedApp(e) {
    if (e === "") return;
    try {
      document.getElementById("filterStatus").value = "ALL";
      const query = {
        partnerName: { wildcard: `*${e.toLowerCase()}*` },
      };
      const appData = await API.graphql(
        graphqlOperation(searchSharedApps, { filter: { and: query } } )
      );
      if (appData.data.searchSharedApps.items.length > 0) {
        const apps = appData.data.searchSharedApps.items;
        if (apps && apps.length > 0) {
          setSharedApps([...apps]);
          setSharedAppsCount(appData.data.searchSharedApps.total);
          handleResetPage();
        }
      }
    } catch (err) {
      console.log("error fetching Shared App", err);
    }
  }

  const loadSharedApp = async (client) => {
    navigate(`../edit/${client}`, { replace: false });
  };

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      addSnack({ variant: 'success', message: 'Link copied to clipboard' });
    } catch (err) {
      err.log("Failed to copy!");
      addSnack({ variant: 'danger', message: 'Error copying to clipboard' });
    }
  };

  const handleNextPage = () => {
    console.log('handleNextPage');
    setPreviousToken((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    console.log('handlePreviousPage');
    setNextToken(previousTokens.pop());
    setPreviousToken([...previousTokens]);
    setNextNextToken(null);
    setCurrentPageIndex(currentPageIndex - 1);
  };

  const handleResetPage = () => {
    setNextToken(undefined);
    setPreviousToken([]);
    setNextNextToken(null);
  }

  const deleteIt = (id, clientId) => {
    console.log(id, clientId);
    setAppId(appId);
    setAppClientId(appClientId);
    toggleOpen(true);
  }

  const deleteApp = async () => {
    console.log(appId);
    try {
        await API.graphql(
          graphqlOperation(deleteSharedApp, { input: { id: appId }})
        );
        toggleOpen(false);
        fetchSharedApps();
    } catch (err) {
      console.log("error deteting Shared App", err);
      toggleOpen(false);
      addSnack({ variant: 'danger', message: 'Error deleting Shared App' });
    }
  }

  const newQuickLink = async (id) => {
    navigate(`../edit/new`, { replace: false });
  };

  const getCategory = (category) => {
    if (category === "HOME_IMPROVEMENT") return HOME_IMPROVEMENT
    else if (category === "ROOF") return ROOF;
    else if (category === "SOLAR") return SOLAR;
    else if (category === "BATTERY") return BATTERY;
  }

  const getCorp = (integratorId, bundleClientId) => {
    if (!!integratorId) return  CORP
    else if (!!bundleClientId) return PLUS
    else return BLANK
  }

  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut }) => (
        <>
          {spinner && <Loading />}
          <NavBar>
            <LumosButton onClick={signOut} style={styles.signOut}>
              Sign out
            </LumosButton>
          </NavBar>
          <div style={styles.container}>
            <Box>
              <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  marginBottom: "20px",
                  fontSize: "22px",
                }}
              >
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                  alignContent="flex-end"
                  wrap="nowrap"
                  gap="1rem"
                >
                  <View>Partner List <span style={styles.count}>({sharedAppsCount})</span></View>
                  <SearchField
                    ref={inputRef}
                    label="Search"
                    placeholder="Search by Account Name.."
                    size="small"
                    onSubmit={(e) => fetchSharedApp(e)}
                    onClear={() => fetchSharedApps()}
                  />
                </Flex>
              </Heading>
              <Box>
              <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "22px",
                }}
              >
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
              >
                <Box sx={{
                    width: 250,
                    marginBottom: 20
                  }}>
                    <SelectField
                      id="filterStatus"
                      style={styles.input}
                      descriptiveText="Filter by Status"
                      onChange={(e) => fetchSharedAppByStatus(e.target.value)}
                      size="small"
                    >
                      <option value="ALL">All Partners</option>
                      <option value="ACTIVE">{Status.ACTIVE}</option>
                      <option value="INPROGRESS">{Status.INPROGRESS}</option>
                      <option value="NOTACTIVE">{Status.NOTACTIVE}</option>
                      <option value="PENDING">{Status.PENDING}</option>
                      <option value="BLOCKED">{Status.BLOCKED}</option>
                      <option value="TEST">{Status.TEST}</option>
                    </SelectField>
                </Box>
                <LumosButton onClick={newQuickLink} style={styles.newQuickLinkButton}>
                  New QuickLink
                </LumosButton>
              </Flex>
              </Heading>
              </Box>
              {SharedApps.length ? (
                <>
                <Table caption="" highlightOnHover={false} size="small" variation="striped">
                  <TableHead>
                    <TableRow>
                      <TableCell as="th">Status</TableCell>
                      <TableCell as="th">Client Id</TableCell>
                      <TableCell as="th">Last Updated</TableCell>
                      <TableCell as="th">Cat</TableCell>
                      <TableCell as="th">Org</TableCell>
                      <TableCell as="th"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {SharedApps.map(({id, status: st, clientId, category, updatedAt, formUrl, integratorId, bundleClientId }, index) => (
                      <TableRow key={id ? id : index}>
                        <TableCell><Badge variant={StatusVariant.find(el => el.type === st).variant}>{`${Status[st]}`}</Badge></TableCell>
                        <TableCell>{clientId}</TableCell>
                        <TableCell>{formatDateTime(updatedAt)}</TableCell>
                        <TableCell>
                          <img src={getCategory(category)} alt={category} style={styles.catLogo} />
                        </TableCell>
                        <TableCell>
                         <img src={getCorp(integratorId, bundleClientId)} alt={integratorId ? integratorId : bundleClientId ? bundleClientId : ""} style={styles.corpLogo} />
                        </TableCell>
                        <TableCell>
                          <View width="4rem">
                            <Menu menuAlign="start" size="small">
                             <MenuItem size="small" onClick={() => loadSharedApp(clientId)}><Icon variant="edit" size="sm" sx={{marginRight: '5px'}} />Edit</MenuItem>
                              <MenuItem size="small" onClick={() => deleteIt(id, clientId)}><Icon variant="trash" size="sm" sx={{marginRight: '5px'}} />Delete</MenuItem>
                              <MenuItem size="small" onClick={() => {copyToClipBoard(formUrl)}}><Icon variant="clipboard" size="sm" sx={{marginRight: '5px'}} />Copy QL to Clipboard</MenuItem>
                            </Menu>
                          </View>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box sx={{ marginTop: 20 }}>
                <Flex
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  alignContent="flex-start"
                  wrap="nowrap"
                  gap="1rem"
                >
                {totalPages > 0 && (
                  <>
                    <LumosButton disabled={!hasPrev} onClick={handleResetPage} style={styles.paginationButton}>
                      <BiChevronsLeft size={'1rem'} /> First Page
                    </LumosButton>
                    <LumosButton disabled={!hasPrev} onClick={handlePreviousPage} style={styles.paginationButton}>
                      <BiChevronLeft size={'1rem'} /> Prev Page
                    </LumosButton>
                    <LumosButton disabled={!hasNext} onClick={handleNextPage} style={styles.paginationButtonRight}>
                      Next Page <BiChevronRight size={'1rem'} />
                    </LumosButton>
                    <SelectField
                      id="pageSize"
                      style={styles.select}
                      descriptiveText="Page Size"
                      onChange={(e) => handlePageSizeChange(e.target.value)}
                      size="small"
                      value={pageSize}
                    >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="35">35</option>
                  </SelectField>
                  </>
                )}
              </Flex>
                </Box>
                </>
              ) : (
                <AlertInline variant="info">No items found.</AlertInline>
              )}
            </Box>
            <Box
              sx={{
                marginBottom: "100px",
              }}
            >
              &nbsp;
            </Box>
          </div>
          <Modal isOpen={isOpen} onDismiss={() => toggleOpen(false)} title="Delete Shared App">
          <Heading
                sx={{
                  backgroundColor: "#ffffff",
                  fontSize: "18px",
                }}
              >
            Are you sure you want to delete {appClientId ? appClientId : 'it'}?
            </Heading>
            <ModalControls actions={[
                {
                  onClick: () => deleteApp(),
                  label: 'Delete',
                },
                {
                  onClick: () => toggleOpen(false), label: 'Cancel'
                }
              ]}
            />
          </Modal>
        </>
      )}
    </Authenticator>
  );
};

const styles = {
  container: {
    maxWidth: "960px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    padding: "20px 20px"
  },
  divider: { margin: "25px 0", color: "white" },
  space: { marginTop: "20px" },
  app: { marginBottom: 15 },
  input: {
    marginBottom: 20,
    padding: 6,
    fontSize: 16,
    // width: "250px"
  },
  select: {
    marginBottom: 0,
    padding: 6,
    fontSize: 12,
    // width: "30px"
  },
  switch: {
    marginBottom: 10,
    // padding: 8,
    fontSize: 16,
  },
  appClientId: { fontSize: 20, fontWeight: "bold" },
  appFields: { marginBottom: 0 },
  button: {
    backgroundColor: "#003439",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "pointer",
  },
  menuButton: {
    backgroundColor: "#00838f",
  },
  newQuickLinkButton: {
    width: 150,
    height: 40,
  },
  buttonDisabled: {
    opacity: 0.8,
    backgroundColor: "#007680",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
    cursor: "notAllowed",
  },
  signOut: {
    marginRight: 10,
  },
  logo: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  paginationButton: {
    marginTop: "30px",
    width: 130,
    height: 40,
  },
  paginationButtonRight: {
    width: 130,
    height: 40,
    marginTop: "30px",
    marginLeft: "200px"
  },
  heading: {
    backgroundColor: "#003439",
  },
  clipboardButton: {
    cursor: "pointer",
  },
  catLogo: {
    width: 25,
    position: "relative",
    top: 5,
    margin: "0 auto",
  },
  corpLogo: {
    width: 25,
    position: "relative",
    top: 5,
    margin: "0 auto",
  },
  count: {
    fontSize: "80%",
    fontWeight: 100,
    position: "relative",
    top: -1
  }
};

export default Partners;
